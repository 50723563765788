import BaseStaticAction from "~/packages/core/actions/BaseStaticAction";
import CryptoJS from "crypto-js";

export default class LaravelDecrypt extends BaseStaticAction {
    static execute(encrypted_data:any, decription_key: string) {
        encrypted_data = CryptoJS.enc.Base64.parse(encrypted_data)
        encrypted_data = JSON.parse(encrypted_data.toString(CryptoJS.enc.Utf8));
        let iv = CryptoJS.enc.Base64.parse(encrypted_data.iv);
        let decrypted: any = CryptoJS.AES.decrypt(encrypted_data.value, CryptoJS.enc.Base64.parse(decription_key), {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return JSON.parse(CryptoJS.enc.Utf8.stringify(decrypted));
    }
}