import BaseApi from "~/packages/core/services/BaseApi";

class CloneAssetsApi extends BaseApi {
    async get(type:string, id:number){
       try{
           let url = `assets_clone/${type}/${id}`

           const { data: response } = await this.makeRequest(url, this.GET);
           return response
       }
       catch(error){
           useLog().trace(error)
       }
    }
}

export default CloneAssetsApi;