import WhitepaperModel from '~/packages/core/models/WhitepaperModel';
import Collection from '~/packages/core/models/Collection';

class WhitepapersCollection extends Collection {
    /**
     * Define associated model to the WhitepapersCollection
     */
    model(): typeof WhitepaperModel {
        return WhitepaperModel;
    }

    /*Getters*/
    get selectOptions() {
        return this.map((asset: any) => {
            return { value: asset.title, title: asset.id + ' - ' + asset.title, id: asset.id }
        });
    }
}

export default WhitepapersCollection;