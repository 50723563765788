import Model from "~/packages/core/models/Model";
import { type ModelOptions } from "~/packages/core/models/BaseModel";
import BloggersCollection from "~/packages/core/models/content/BloggersCollection";

class BlogsModel extends Model {
    static permission_name:string = "blog"
    casts() {
        return {
            blog_id: "number",
            name: "string",
            tag_line: "string",
            description: "string",
            blog_type: "string",
            rss_url_B: "string",
            rss_url_C: "string",
            rss_url_A: "string",
            color: "string",
            color2: "string",
            image_small: "string",
            image_medium: "string",
            image_header: "string",
            image_read:"string" ,
            image_rss:"string" ,
            meta_keywords: "string",
            meta_description: "string",
            blogger: BloggersCollection
        };
    }
    /**** GETTERS ****/
    /**** GETTERS END ****/

    /**** METHODS ****/

    /**
     * Getter example use case
     */
    // @ts-ignore
    // get exerpt(): string {
    //   if (this.description) {
    //     return this.description.substring(0, 30) + ...;
    //   }
    // }
     // blog_type options
    blogTypeOptions(){
        return  [
            {
                optionsText: "Main",
                optionsValue: "main",
            },
            {
                optionsText: "Vendor",
                optionsValue: "vendor",
            },
            {
                optionsText: "Anonymous",
                optionsValue: "anonymous",
            },
        ]
    }
    getDefaultOptions(): ModelOptions {
        return {
            identifier: "blog_id",
        };
    }
    /**** METHODS END ****/
    /**
     * uncomment this method in the model, if current model has different, key name, than
     * the default ('id')
     */

    /**SETTERS */
}

export default BlogsModel;
