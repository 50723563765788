import { merge } from "lodash";
import AssetModel from "~/packages/core/models/AssetModel";
import AuthorsCollection from "~/packages/core/models/AuthorsCollection";
import CountryWithStatesModel from "~/packages/core/models/CountryWithStatesModel";
import StatesModel from "~/packages/core/models/StatesModel";

class PressreleaseModel extends AssetModel {
    static permission_name:string = "press_release";
    /**
     * While interviews have a different type convention (instead of 'interview' as asset type, it must be 'podcast')
     * we need to force the right type
     */
    casts() {
        return merge(super.casts(), ({
            old_id: "number",
            authors: AuthorsCollection,
            listing_type: "string",
            company: "string",
            address: "string",
            address_2: "string",
            city: "string",
            country:"string",
            state:"string",
            state_other: "string",
            zipcode: "string",
            phone: "string",
            start_date: "string",
            title: "string",
            document: "string",
            active: "string",
            is_ismg_press: "number",
            vendor: "string",
            country_list:CountryWithStatesModel,
            state_list: StatesModel,
        }));
    }
    /**** GETTERS ****/

    /**
     * Getter example use case
     */
    // @ts-ignore
    // get exerpt(): string {
    //   if (this.description) {
    //     return this.description.substring(0, 30) + ...;
    //   }
    // }
    /**** GETTERS END ****/
    /**
     * uncomment this method in the model, if current model has different, key name, than
     * the default ('id')
     */

    /**SETTERS */
    //setting vendor id on sponsor form
    set SelectedCountry(country:any){
        this.country = country.value
    }

    set SelectedState(state:any){
        this.state = state.value
    }
}

export default PressreleaseModel;
