import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import PopupsCollection from "~/packages/core/models/marketing/PopupsCollection";
import PopupModel from "~/packages/core/models/marketing/PopupModel";

class PopupsApi extends DefaultApi {
  protected path:string = 'popups'
  protected collection:BaseCollection = PopupsCollection
  protected model:BaseModel = PopupModel
}


export default PopupsApi