import Model from "~/packages/core/models/Model";
import TitleLevelsModel from "~/packages/core/models/TitleLevelsModel";
import JobFunctionModel from "~/packages/core/models/JobFunctionModel";
import SubscriptionMatrixModel from "~/packages/core/models/users/SubscriptionMatrixModel";
import CountryWithStatesModel from "~/packages/core/models/CountryWithStatesModel";
import StatesModel from "~/packages/core/models/StatesModel";

class UserModel extends Model {
    casts() {
        return {
            id: "number",
            old_id: "number",
            name_first: "string",
            name_last: "string",
            address: "string",
            city: "string",
            state: "string",
            state_other: "string",
            country: "string",
            zip: "string",
            phone: "string",
            fax: "string",
            email: "string",
            website: "string",
            inst_name: "string",
            title: "string",
            referrer: "string",
            rf_source: "string",
            enews: "string",
            jenews: "string",
            wp: "string",
            webinars: "string",
            podcasts: "string",
            blogs: "string",
            active: "number",
            active_reason: "string",
            username: "string",
            epassword: "string",
            training_id: "string",
            enewser: "string",
            rss: "string",
            vendor_enews: "string",
            ws: "string",
            account_id: "string",
            company_id: "number",
            title_id: "number",
            job_id: "number",
            is_acq: "number",
            source: "string",
            twitter_handle: "string",
            emergency_contact: "string",
            emergency_phone: "string",
            special_dietary_requirements: "string",
            keep_inform: "string",
            upload_source: "string",
            title_descriptive: "string",
            emails_additional: "string",
            phone_mobile: "string",
            phone_alternate: "string",
            contact_office_location_phone: "string",
            direct_office_phone: "string",
            researcher: "string",
            contact_tier: "string",
            linkedin_profile_url: "string",
            research_pid: "string",
            self_date_updated: "string",
            attended_rt: "string",
            account_name: "string",
            user_state: "string",
            raw_title: "string",
            full_name: "string",
            full_name_separated_by_comma: "string",
            reg_errors_flags: "string",
            password_1:"string",
            verified:"string",
            title_relation:TitleLevelsModel,
            job_function:JobFunctionModel,
            email_subscriptions: SubscriptionMatrixModel,
            country_data:CountryWithStatesModel,
            state_data: StatesModel,
        }
    }

    /** GETTERS  **/

    get attendedRTOptions () {
        return [
            {
                "optionsText": "Yes",
                "optionsValue": "yes"
            },
            {
                "optionsText": "No",
                "optionsValue": "no"
            },
        ]
    }

    /** GETTERS **/
    //get selected options for select
    get SelectedTitleOptions () {
     return { value : this.title_relation.id, title: this.title_relation.title }
    }

    get SelectedJobID () {
        return { value: this.job_id, name: this.job_id }
    }

    /** SETTERS **/
    //set title_id by grabbing the id from select
    set SelectedTitleId(title_levels:any){
        this.title_id = title_levels.value
    }

    //set job_id by grabbing the id from select
    set SelectedJobId(job:any){
        this.job_id = job.value
    }

    set SelectedCountry(country:any){
        this.country = country.value
    }

    set SelectedState(state:any){
        this.state = state.value
    }
}

export default UserModel