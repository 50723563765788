import Collection from "~/packages/core/models/Collection";
import PopupModel from "~/packages/core/models/marketing/PopupModel";

class PopupsCollection extends Collection {
    //define PopupModel to return model
    model(): typeof PopupModel {
        return PopupModel
    }

    /**** GETTERS ****/

}

export default PopupsCollection