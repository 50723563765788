import { merge } from "lodash";
import {type ModelOptions } from "~/packages/core/models/BaseModel";
import AssetModel from "~/packages/core/models/AssetModel";
import AuthorsCollection from "~/packages/core/models/AuthorsCollection";
import CategoriesCollection from "~/packages/core/models/CategoriesCollection";
import RelatedAssetsCollection from "~/packages/core/models/RelatedAssetsCollection";
import VendorDirectoryCollection from "~/packages/core/models/marketing/VendorDirectoryCollection";
import BlogsModel from "~/packages/core/models/content/BlogsModel";

class BlogPostModel extends AssetModel {
  static permission_name: string =  'blog';
  casts() {
    return merge(super.casts(), {
      post_id: "number",
      blog_id: "number",
      blogger_id: "number",
      vendor_id: "number",
      authors: AuthorsCollection,
      title: "string",
      sub_heading: "string",
      teaser: "string",
      content: "string",
      pull_quote: "string",
      active_campaign: "number",
      gated_asset: "number",
      sponsorship_logo: "number",
      required_level: "number",
      required_level_vp: "number",
      meta_title: "string",
      meta_keywords: "string",
      meta_description: "string",
      stamp: "string",
      website: "string",
      on_front_page: "string",
      sponsored: "number",
      email_teaser: "string",
      email_title: "string",
      popup_details: "object",
      image: "string",
      image_large: "string",
      showcase_image: "string",
      image_caption: "string",
      in_latest_news: "string",
      canonical_url: "string",
      vendor_directory: VendorDirectoryCollection,
      premium: "object",
      categories: CategoriesCollection,
      related: RelatedAssetsCollection,
      blog: BlogsModel
    });
  }
  /**** GETTERS ****/

  /**
   * Getter example use case
   */
  // @ts-ignore
  // get exerpt(): string {
  //   if (this.description) {
  //     return this.description.substring(0, 30) + ...;
  //   }
  // }
  /**** GETTERS END ****/
  /**
   * uncomment this method in the model, if current model has different, key name, than
   * the default ('id')
   */
  get id(){
    return this.post_id
  }
  getDefaultOptions(): ModelOptions {
    return {
      identifier: "post_id",
    };
  }

  /**SETTERS */
  //setting vendor id on sponsor form
  set VendorID(vendorID: Number) {
    //@ts-ignore
    if (this.vendor_id != vendorID) this.vendor_id = vendorID;
  }

  set blogID(blog:any){
    this.blog_id = blog.id;
  }

  //email content
  // set InterviewEmailContent(email_content: string) {
  //     this.email_content = email_content ===  ? this.description : email_content;
  // }
}

export default BlogPostModel;
