class MainProcess {
    readonly BASE_PATH: string = '/dashboard'

    readonly UNAUTHORIZED_PATH: string = '/unauthorized'

    readonly PLACEHOLDER_DARK_IMAGE_PATH: string = 'icons/img-placeholder-dark.jpeg'

    readonly PLACEHOLDER_LIGHT_IMAGE_PATH:string = 'icons/img-placeholder-light.png'

    readonly DARK_MODE_STRING = 'dark'
}

export default new MainProcess()