import Model from "~/packages/core/models/Model";
import  {type ModelOptions} from "~/packages/core/models/BaseModel";
import CountedAssetViewModel from "~/packages/core/models/content/relation_models/CountedAssetViewModel";
import BooleanCheckRenderer from "~/packages/core/actions/html_renderers/BooleanCheckRenderer";

class AssetModel extends Model {
    casts() {
        return {
            asset_id: "number",
            asset_type: "string",
            asset_media_url: "string",
            image_small: "string",
            image_medium: "string",
            image_large: "string",
            counted_asset_views: CountedAssetViewModel,
        };
    }
    /**** GETTERS ****/
    // @ts-ignore
    get author_names(): string {
        if (!this.authors.length) return '';

        return this.authors.map(author => author.name).join(', ');
    }

    get views_number(): number {
        if (!this.counted_asset_views) return 0;

        return this.counted_asset_views.views;
    }

    /**** GETTERS END ****/
    /**
     * uncomment this method in the model, if current model has different, key name, than
     * the default ('id')
     */
    getDefaultOptions(): ModelOptions {
        return {
            identifier: 'asset_id'
        }
    }

    /**
     * override for assets that have a different column to store the pdf file generated thumb image
     */
    getPdfThumbColName(): string {
        return 'logo' // this is default as it is the thumb column name used for whitepapers and for handbooks
    }

    // Setter for logo to set a new value and also update image properties
    setThumb(thumb: string, thumb_col_name: string) {
        if (this[thumb_col_name] !== thumb) {
            this[thumb_col_name] = thumb;

            // When logo changes, update image_* properties as well
            this.image_small = thumb;
            this.image_medium = thumb;
            this.image_large = thumb;
        }
    }

    /***
     * this method will take an input array and converts each array element to independent value and also check for numeric values that are in string type converts that to Number
     * @param inputArr
     * */
    extractExistingMatrixValues(inputArr: any[]): any[] {
        const active_data: any[] = [];
        // if (inputArr) {
        //     for (let i = 0; i < inputArr.length; i++) {
        //         const convertedElement = !isNaN(Number(inputArr[i])) ? Number(inputArr[i]) : inputArr[i];
        //         active_data.push(convertedElement);
        //     }
        // }
        // return active_data;
    }

    //Assets select Options
    sponsoredOptions = [
        {
            optionsText: "Long",
            optionsValue: 1,
        },
        {
            optionsText: "Medium",
            optionsValue: 2,
        },
        {
            optionsText: "Short",
            optionsValue: 3,
        },
    ]

    languageOptions = [
        {
            optionsText: "English",
            optionsValue: "en",
        },
        {
            optionsText: "Japanese",
            optionsValue: "jp",
        },
    ];

    //TODO - re-visit after content-hubs & survey integration
    static assetOptions = [
        {
            optionsText: "Article",
            optionsValue: "article",
        },
        {
            optionsText: "Blog Post",
            optionsValue: "blogpost",
        },
        {
            optionsText: "Podcast(Interview)",
            optionsValue: "podcast",
        },
        {
            optionsText: "Handbook",
            optionsValue: "handbook",
        },
        {
            optionsText: "Webinar",
            optionsValue: "webinar",
        },
        {
            optionsText: "Whitepaper",
            optionsValue: "whitepaper",
        },
    ];

}

export default AssetModel;
