import BlogPostModel from '~/packages/core/models/BlogPostModel';
import AssetCollection from '~/packages/core/models/AssetCollection';

class BlogPostCollection extends AssetCollection {
  /**
   * Define associated model to the BlogPostCollection
   */
  model(): typeof BlogPostModel {
    return BlogPostModel;
  }
}

export default BlogPostCollection;
