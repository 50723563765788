import BaseStaticAction from "~/packages/core/actions/BaseStaticAction";

/**
 * This class provides a method to navigate one level up in the current route hierarchy.
 */
export default class GoBack extends BaseStaticAction {
    static execute() {
        // Split the current path into segments
        const pathSegments = useNuxtApp().$NuxtRoute.path.split('/').filter(Boolean);

        // Remove the last segment to go one level up
        pathSegments.pop();

        // Join the segments back to form the new path
        const newPath = `/${pathSegments.join('/') || ''}`;

        // Navigate to the new path
        useNuxtApp().$NuxtRouter.push(newPath || '/');
    }
}