import ArticleModel from '~/packages/core/models/ArticleModel';
import AssetCollection from '~/packages/core/models/AssetCollection';

class ArticleCollection extends AssetCollection {
  /**
   * Define associated model to the ArticleCollection
   */
  model(): typeof ArticleModel {
    return ArticleModel;
  }

  get selectOptions() {
    return this.map((asset: any) => {
      return { value: asset.title, title: asset.article_id + ' - ' + asset.title, id: asset.article_id }
    });
  }
}

export default ArticleCollection;
