import Model from "~/packages/core/models/Model";
import CampaignFilterModel from "~/packages/core/models/marketing/CampaignFilterModel";
import AssetModel from "~/packages/core/models/AssetModel";

class PopupModel extends Model {
  static permission_name:string = "asset_popup";
  casts() {
    return {
      id: "number",
      name: "string",
      asset_type: "string",
      popup_type: "string",
      asset_id: "number",
      no_subscribe_form: "number",
      custom_html: "string",
      redirect: "string",
      cs_campaigns_lead_filter_id: "number",
      date_created: "string",
      sites_concat: "string",
      display_criteria: "string",
      prequal: "string",
      campaign_filter: CampaignFilterModel,
      asset: AssetModel
    };
  }

  get SelectedCampaign () {
    return { value : this.campaign_filter.id, name: this.campaign_filter.name }
  }

  get SelectedAsset () {
    return { value : this.asset.id, title: this.asset.title }
  }

  assetQueryMap:any = {
    'interview' : { fields:['title','podcast_id'], order_direction:'asc', search_fields:'title,podcast_id' },
    'webinar' : { fields:['title','webinar_id'], order_direction:'asc', search_fields:'title,webinar_id' },
    'blogpost' : { fields:['title','post_id'], order_direction:'asc', search_fields:'title,post_id' }
  }

  //Method to return query for assets
   getQuery () {
    if(this.asset_type == 'interview' || this.asset_type == 'webinar' || this.asset_type == 'blogpost'){
      return this.assetQueryMap[this.asset_type]
    }
    else {
      return { fields:['title','id'], order_direction:'asc', search_fields:'title,id' }
    }
  }

  // asset types
  asset_types = [
    {
      optionsText: "Custom",
      optionsValue: "custom",
    },
    {
      optionsText: "Handbook",
      optionsValue: "handbook",
    },
    {
      optionsText: "Interview",
      optionsValue: "interview",
    },
    {
      optionsText: "Survey",
      optionsValue: "survey",
    },
    {
      optionsText: "Webinar",
      optionsValue: "webinar",
    },
    {
      optionsText: "Whitepaper",
      optionsValue: "whitepaper",
    },
    {
      optionsText: "Blogpost",
      optionsValue: "blogpost",
    }
  ]
}

export default PopupModel;
