import { useGlobalState } from '~/store/useGlobalState'
import PermissionCollection from "~/packages/core/models/PermissionCollection";
import PermissionModel from "~/packages/core/models/PermissionModel";
import {get} from "lodash";
import Model from "~/packages/core/models/Model";

class RoleModel extends Model {
    casts() {
        return {
            id: "string",
            title: "string",
            alias: "string",
            map_id: "string",
            map_name: "string",
            description: "string",
            is_active: "boolean",
            permissions: PermissionCollection
            // created_at: "string",
            // updated_at: "string"
        };
    }

    /**
     * create a permission boolean selection object by selected permissions for current role
     * used on roles add/edit page in PermissionHandler component (custom-auth-role-permission-handler)
     */
    async getPermissionSelection() {
        const permissions:any = await useApi()['permission'].list_all()
        let selections:any = {}

        for(let entity in permissions.groupedByEntity) {
            let models:PermissionModel[] = permissions.groupedByEntity[entity]
            selections[entity] = this.checkPermissionEntities(permissions, entity)
            models.forEach((model: PermissionModel) => {
                selections[model.action] = this.checkPermission(entity, model.action)
            })
        }

        return selections
    }

    /**
     * check if current role permissions selection, has all actions for provided entity
     * @param permissions
     * @param entity
     */
    checkPermissionEntities(permissions: PermissionCollection, entity: string) {
        return get(this, ['permissions', 'groupedByEntity', entity], []).length == get(permissions, ['groupedByEntity', entity], []).length ? true : false;
    }

    /**
     * check if permission action, exists in current role permissions
     * @param permissions
     * @param entity
     * @param action
     */
    checkPermission(entity: string, action: string) {
        const currentRoleEntityPermissions = get(this, ['permissions', 'groupedByEntity', entity], [])
        if(currentRoleEntityPermissions.length) {
            for(let k in currentRoleEntityPermissions) {
                let model: PermissionModel = currentRoleEntityPermissions[k]
                if(model.action == action) {
                    return true
                }
            }
        }
        return false
    }

    /**** GETTERS ****/

    /**
     * Getter example use case
     */
    // @ts-ignore
    get gridColDefs(): string[] | object[] {
        let {RolesDefinitions} = useGlobalState();
        return RolesDefinitions
    }

    /**** GETTERS END ****/
    /**
     * uncomment this method in the model, if current model has different, key name, than
     * the default ('id')
     */
    // getDefaultOptions(): ModelOptions {
    //     return {
    //         identifier: 'post_id'
    //     }
    // }
}

export default RoleModel;
