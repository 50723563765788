import CategoryModel from '~/packages/core/models/CategoryModel';
import Collection from '~/packages/core/models/Collection';
import CategoryPivotModel from './CategoryPivotModel';

class CategoriesCollection extends Collection {
    private extractedNames:any = []
    /**
     * Define associated model to the CategoriesCollection
     */
    model(): typeof CategoryModel {
        return CategoryModel;
    }

    /*GETTERS*/

    //Sets property to find the checked option
    setCheckProperty(categories: any, propertyName: any) {
        return categories.map((category: any) => {
            category[propertyName] = 0;
            if (category.children && Array.isArray(category.children)) {
                category.children = this.setCheckProperty(
                    category.children,
                    propertyName
                );
                category.children.map((category: any) => {
                    return category.pivot = new CategoryPivotModel()
                })
            }
            return category;
        });
    };

    // Common filtering Methods which returns the values based on searchkeyword
    filterCategories(categories: any, searchKeyword: any) {
        const filterCategory = (category: any) => {
            return (
                category.name.toLowerCase().indexOf(searchKeyword) !== -1 ||
                (category.children &&
                    category.children.some((child: any) => filterCategory(child)))
            );
        };

        return categories.filter((category: any) => filterCategory(category));
    };

    //get the selected categories 
    getSelectedCategories(contentType: any, contentID: any, categories: any) {
        const filterCategory = (category: any) => {
            if (category.isPrimaryChecked === 1) {
                category.pivot = new CategoryPivotModel({
                    cat_id: category.cat_id,
                    content_type: contentType,
                    content_id: contentID,
                    is_basic: 1
                })
                return true;
            }
            if (category.isSecondaryChecked === 1) {
                category.pivot = new CategoryPivotModel({
                    cat_id: category.cat_id,
                    content_type: contentType,
                    content_id: contentID,
                    is_basic: 0
                })
                return true;
            }
            if (category.children && category.children.length > 0) {
                return category.children.some((child: any) => filterCategory(child));
            }
            return false;
        };

        const flattenCategories = (categories: any) => {
            let result: any[] = [];
            categories.forEach((category: any) => {
                if (filterCategory(category)) {
                    result.push(category.pivot);
                }
                if (category.children && category.children.length > 0) {
                    result = result.concat(flattenCategories(category.children));
                }
            });
            return result;
        };
        return flattenCategories(categories);
    };

    //Get exisiting categories from asset and check those categories on UI
    getExistingCategories(fetchedCategories: any, existingCategories: any) {
        return fetchedCategories.map((newcategory: any) => {
            return existingCategories.filter((category: any) => {
                if (newcategory.cat_id === category.cat_id) {
                    if (category.pivot.is_basic === 1) {
                        // console.log(newcategory);
                        newcategory.isPrimaryChecked = 1;
                    }
                    if (category.pivot.is_basic === 0) {
                        // console.log(newcategory);
                        newcategory.isSecondaryChecked = 1;
                    }
                }
                // console.log(category);
                if (newcategory.children) {
                    this.getExistingCategories(newcategory.children, existingCategories);
                    // console.log(category.children);
                }
            });
        });
    }

    // get selected category id's in an array
    getSelectedCategoryIds = (selectedCategories:any) =>{
        let selectedIds:any = []
        selectedCategories.forEach((category:any) => {
            selectedIds.push(category.cat_id)
        })
        return selectedIds
    }


    /*GETTERS*/
    get primaryCategories() {
        return this.setCheckProperty([...this], "isPrimaryChecked");
    }

    get secondaryCategories() {
        return this.setCheckProperty([...this], "isSecondaryChecked");
    }

    /** there are instances where we need categories in multiselect
     * for that we need to structure the categories in the following format which supports groups and common selection
     *  { cat_id: 1, value: 1, label: 'Category 1', options: [...] }
     * **/
    get categoriesSelectOptions(): {} {
        return this.map((category: CategoryModel) => {
            // @ts-ignore
            return { cat_id: category.cat_id, value: category.cat_id, label: category.name, options: this.categoryChildrenOptions(category) }
        });
    }

    /* helper method to get the children in an object for multiselect
    * @param category
    * @returns {any[]}
     */
    categoryChildrenOptions(category: any[]): any[] {
        //When children available group them by showing label
        if(category.children && category.children.length > 0) {
            return category.children.map((cat: any) => {
                return  { cat_id: cat.cat_id, value: cat.cat_id, label: cat.name };
            });
        }
        //When there is no children tranform label as select option
        return [{cat_id: category.cat_id, value: category.cat_id, label: category.name}];
    }

}

export default CategoriesCollection;
