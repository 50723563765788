import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseModel from "~/packages/core/models/BaseModel";
import AssetViewsModel from "~/packages/core/models/reports/AssetViewsModel";

class AssetViewsApi extends DefaultApi {
    protected path:string = 'assets_views'
    protected model:BaseModel = AssetViewsModel
}


export default AssetViewsApi