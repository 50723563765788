/*
* useAssets extends defaultApi composable to use Api state and Common Api Notifications
 */

import type {Payload} from "~/packages/core/types/Api";
import {useGlobalState} from "~/store/useGlobalState";
import BaseModel from "~/packages/core/models/BaseModel";
import GoBack from "~/packages/core/actions/utility/GoBack";

class useAssets extends useDefaultApi {
    protected api:string = 'assets'
    protected excluded:string[] = ['authors', 'premium', 'prequal', 'interviewee_list', 'related', 'vendor_directory']

    async getSearchedAsset(type: string, query: any, payload:any) {
        if (query) {
            //@ts-ignore
            return await useApi()[this.api].searchAssets(type, query, payload)
        }
    }


    async get(id: any, options?:{ payload?: Payload, url?: any }) {
        try {
            options.payload = options?.payload || {
                with: ['authors', 'prequal', 'vendorDirectory', 'premium', 'related', 'categories'],
            }
            //@ts-ignore
            return await useApi()[this.api].get(id, options?.payload, options?.url);
        } catch (error) {
            useNotification().notify("error", `${error}`);
        }
    }


    async create(model: BaseModel, options?:{payload?:Payload, url?:string}) {
        useGlobalState().isApiLoading = true;
        try {
            //@ts-ignore
            const entity = await useApi()[this.api].create(
                model.getModelData(this.excluded), options?.payload, options?.url
            );
            if (entity) {
                if (entity.metadata.status === "200") {
                    // @ts-ignore
                    useNotification().notify('success',entity.metadata.response_details.messages[0]);
                    GoBack.execute();
                }
                model.asset_id = entity.asset_id;
                if (model) {
                    return await this.handleRelation('authors', model, {payload:options?.payload, url:options?.url
                    })
                }
            }
            if (entity.metadata.status === "200") {
                // @ts-ignore
                useNotification().notify(
                    "success",
                    entity.metadata.response_details.messages[0],
                );
            }
        } catch (error) {
            //@ts-ignore
            useNotification().notify("error", `${error.message}`);
        } finally {
            useGlobalState().isApiLoading = false;
        }
    }

    async update(model: BaseModel, options?:{payload?:Payload, url?:string}) {
        useGlobalState().isApiLoading = true;
        try {
            //@ts-ignore
            const updatedAsset = await useApi()[this.api].update(
                model.getModelData(this.excluded),
                model.asset_id,
                options?.payload,
                options?.url
            );
            if (updatedAsset) {
                if (updatedAsset.metadata.status === "200") {
                    // @ts-ignore
                    useNotification().notify('success', updatedAsset.metadata.response_details.messages[0]);
                }
            }
            if (model) {
                return await this.handleRelation('authors', model, {payload:options?.payload, url:options?.url
            })
            }
        } catch (error) {
            //@ts-ignore
            useNotification().notify("error", `${error.message}`);
        } finally {
            useGlobalState().isApiLoading = false;
        }
    }

    async handleRelation(relation: any, model: any, options?:{ payload?: Payload, url?: any }) {
        try {
            //@ts-ignore
            const entity = await useApi()[this.api].handleRelation(
                relation,
                model,
                options?.payload,
                options?.url,
            );
            if (entity) {
                if (entity.meta.status === "200") {
                    //@ts-ignore
                    useNotification().notify(
                        "success",
                        entity.meta.response_details.messages[0],
                    );
                }
            }
        } catch (error) {
            //@ts-ignore
            useNotification().notify("error", `${error.message}`);
        }
    }

    async deleteEntity(id: any, options?:{ payload?: Payload, url?: any }) {
        useGlobalState().isApiLoading = true;
        try {
            //@ts-ignore
            const entity = await useApi()[this.api].delete(id, options?.payload, options?.url);
            if (entity) {
                if (entity.meta.status === "200") {
                    //@ts-ignore
                    useNotification().notify(
                        "success",
                        entity.meta.response_details.messages[0],
                    );
                }
                location.reload();
            }
        } catch (error) {
            //@ts-ignore
            useNotification().notify("error", `${error.message}`);
        }
        finally {
            useGlobalState().isApiLoading = false;
        }
    }



}

export default  useAssets;