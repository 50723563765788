import Model from "~/packages/core/models/Model";
import type {ModelOptions} from "~/packages/core/models/BaseModel";

class BloggerModel extends Model {
  casts(): {} {
    return {
      blogger_id: "number",
      name: "string",
      description: "string",
      email: "string",
      avatar: "string",
      avatar_email: "string",
      meta_keywords: "string",
      meta_description: "string",
      url: "string",
    };
  }

  /** GETTERS **/

    getDefaultOptions(): ModelOptions {
        return {
            identifier: "blogger_id",
        };
    }

    get id(): number {
       return this.blogger_id;
    }
}

export default BloggerModel;
