import PressreleaseModel from '~/packages/core/models/PressreleaseModel';
import Collection from '~/packages/core/models/Collection';

class PressreleasesCollection extends Collection {
    /**
     * Define associated model to the PressreleasesCollection
     */
    model(): typeof PressreleaseModel {
        return PressreleaseModel;
    }

    /*Getters*/
}

export default PressreleasesCollection;