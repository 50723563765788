import Model from "~/packages/core/models/Model";

class BaseContentModel extends Model{
    casts(): {} {
        return {
            version: 'string',
            top_link_title_1: 'string',
            name: 'string',
            sender_name: 'string',
            sender_type: 'string',
            hyperlink_text: 'string',
            subject: 'string',
            referral_utype: 'string',
            referral_custom: 'string',
            referral_segment_code: 'string',
            footer_id: 'string',
            item1_type: 'string',
            item1_id: 'string',
            item1_web: 'string',
            item1_linktp: 'string',
            item2_type: 'string',
            item2_id: 'string',
            item2_web: 'string',
            item2_linktp: 'string',
            item3_type: 'string',
            item3_id: 'string',
            item3_web: 'string',
            item3_linktp: 'string',
        }
    }
}

export default BaseContentModel