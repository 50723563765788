import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseModel from "~/packages/core/models/BaseModel";
import AssetCategoriesModel from "~/packages/core/models/reports/AssetCategoriesModel";

class AssetCategoriesApi extends DefaultApi {
    protected path:string = 'asset_categories'
    protected model:BaseModel = AssetCategoriesModel
}


export default AssetCategoriesApi