import AuthBearer from '~/packages/core/services/AuthBearer'
import {trim, get, merge} from 'lodash'
import BaseModel from '~/packages/core/models/BaseModel'
import BaseCollection from "~/packages/core/models/BaseCollection";
import MainProcess from "~/config/Constants/MainProcess";
import type {Payload} from "~/packages/core/types/Api";
import {ObjectToQuery} from "~/packages/core/utility/ObjectToQuery";
import LoginProcess from "~/config/Constants/LoginProcess";
import {useAuthStore} from "~/store/AuthStore";

export type ApiHeader = {
    key: string
    value: string
}

export const headers = new Headers()
headers.set('Content-Type', 'application/json')
headers.set('Access-Control-Allow-Origin', '*')
headers.set('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE')

const { notify } = useNotification()

class BaseApi {
    readonly GET = 'GET'
    readonly POST = 'POST'
    readonly PUT = 'PUT'
    readonly PATCH = 'PATCH'
    readonly DELETE = 'DELETE'

    constructor() {

    }

    async makeRequest(url: string, type: string, request_options?: object | object[] | BaseModel | BaseCollection, with_bearer: boolean = true) {
        let options = await this.getOptions(with_bearer, headers, type, request_options);

        return await this.fetchRequest(url, options);
    }

    private async fetchRequest(url: string, options: {
        headers: Headers;
        method: string;
        body: object | object[] | BaseModel | BaseCollection | FormData | undefined
    }) {
        try {
            // @ts-ignore
            const response = await useFetch(`${this.configBaseURL()}/${trim(url, '/')}`, options)

            // Handle HTTP errors
            // need to be handled differently on a higher level
            // if (response.error.value) {
            //     throw new Error(response.error.value.message);
            // }

            this.handleUnauthenticated(response)

            this.handleUnauthorized(response)

            return response
        } catch (error) {
            // Handle other exceptions
            throw error
        }
    }

    async makeFileRequest(url: string, type: string, request_options?: object | object[] | BaseModel | BaseCollection | FormData, headers: Headers = new Headers(), with_bearer: boolean = true) {
        let options = await this.getOptions(with_bearer, headers, type, request_options);

        return await this.fetchRequest(url, options);
    }

    private async getOptions(with_bearer: boolean, headers: Headers, type: string, request_options: object | object[] | BaseModel | BaseCollection | FormData | undefined) {
        if (with_bearer && AuthBearer.get() != '') {
            headers.set('Authorization', 'Bearer ' + AuthBearer.get());
        }

        let options = {
            method: type,
            headers: headers,
            credentials: 'include',  // Ensures cookies are sent
            // @ts-ignore
            body: get(request_options, 'post_data', request_options)
        }

        if(request_options?.responseType) {
            options.body.responseType = request_options.responseType;
        }

        if (get(request_options, 'with_csrf')) {
            const token = await this.withCsrf()
            options.body = merge(options.body, {"csrf-token": token})
        }

        return options;
    }

    configBaseURL() {
        let config = useRuntimeConfig()
        return config.public.API_BASE_URL
    }

    setHeaders(localHeaders: ApiHeader[]) {
        localHeaders.forEach((header) => {
            headers.set(header.key, header.value)
        })
    }

    async withCsrf() {
        const {data: response} = await this.csrf()
        this.setHeaders([
            <ApiHeader>{
                key: 'X-XSRF-TOKEN',
                value: response.value
            }
        ])

        return response.value
    }

    getServerUrl() {
        let config = useRuntimeConfig()
        return config.public.API_SERVER
    }

    async csrf() {
            return await this.makeRequest('csrf', this.POST)
    }

    handleUnauthorized(res: any) {
        const status = get(res, 'error.value.cause.status')
        if(status == 403) {
           navigateTo(MainProcess.UNAUTHORIZED_PATH, {replace:false});
        }
    }

    handleUnauthenticated(res: any) {
        const status = get(res, 'error.value.cause.status')
        if(status == 401) {
            useAuthStore().logout()
            navigateTo(LoginProcess.LOGIN_PAGE, { replace: false })
        }
    }

    // TODO: might need adjustments...
    protected handleError(error: any) {
        if (error.value) {
            const err = error.value;
            const {notify} = useNotification();
            switch (err.statusCode) {
                case (500):
                    // Server responded with a status code out of the range of 2xx
                    notify('error', 'Server error!')
                    useLog().trace(`Error: ${err.statusCode}`);
                    useLog().trace(`Data: ${err.data}`);
                    useLog().trace(`Stack: ${err.stack}`);
                    break;
                case (400):
                    if (err.data?.type && err.data?.messages) {
                        useLog().trace(`Error: ${err.statusCode}`);
                        for (let field in err.data.messages) {
                            let error_messages: string[] = err.data.messages[field];
                            error_messages.forEach((message: string) => {
                                useLog().trace(`Message: ${message}`);
                                notify(err.data.type, message);
                            })
                        }
                        useLog().trace(`Stack: ${err.stack}`);
                    }
                    break;
                default:
                    // Something happened in setting up the request that triggered an Error
                    useLog().trace('Error:', err.message);
                    break
            }
        }
    }

    //construct url based on the options provide to make full request_url
    getRequestUrl(path:string, url:string, options?: {  payload?: Payload, id?: any, relation?: string }){
        // @ts-ignore
        let { id, payload, relation } = options;
        const url_query_string = payload ? '?' + ObjectToQuery(payload) : ''
        id = id ? `/${id}` : ''
        relation = relation ? `/${relation}` : ''
        return  url ? path + url + id + relation + url_query_string : path + id + relation + url_query_string
    }
}

export default BaseApi