/*
* useEmailTemplate extends defaultApi composable to use Api state and Common Api Notifications
 */

import BaseModel from "~/packages/core/models/BaseModel";
import {type Payload} from "~/packages/core/types/Api";
import {useGlobalState} from "~/store/useGlobalState";
import GoBack from "~/packages/core/actions/utility/GoBack";

class useEmailTemplate extends useDefaultApi {
    protected api:string = 'email_templates'
    protected excluded:string[] = []

    async create(model: BaseModel, payload?:Payload, url?:string) {
        useGlobalState().isApiLoading = true;
        try {
            //@ts-ignore
            const entity = await useApi()[this.api].create(
                model, payload, url
            );
            if (entity.metadata.status === "200") {
                // @ts-ignore
                useNotification().notify(
                    "success",
                    entity.metadata.response_details.messages[0],
                );
                GoBack.execute();
            }
        } catch (error) {
            //@ts-ignore
            useNotification().notify("error", `${error.message}`);
        } finally {
            useGlobalState().isApiLoading = false;
        }
    }
}

export default  useEmailTemplate;