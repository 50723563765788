/**
 * A logging wrapper for better handling errors, info or debugging messages
 * Messages will need costumizations in the future, like adding a timestamp to each message, or displaying some of them only if debugging will be on etc.
 */
import IsDebug from "~/packages/core/actions/utility/IsDebug";

class Log {

    dump(...data: any[]) {
        if(IsDebug.execute()) {
            return console.log(data)
        }
    }

    info(...data: any[]) {
        if(IsDebug.execute()) {
            return console.info(data)
        }
    }

    debug(...data: any[]) {
        if(IsDebug.execute()) {
            return console.debug(data)
        }
    }

    error(...data: any[]) {
        if(IsDebug.execute()) {
            return console.error(data)
        }
    }

    table(tabularData?: any, properties?: string[]) {
        if(IsDebug.execute()) {
            return console.table(tabularData, properties)
        }
    }

    trace(...data: any[]) {
        if(IsDebug.execute()) {
            return console.trace(data)
        }
    }

    warn(...data: any[]) {
        if(IsDebug.execute()) {
            return console.warn(data)
        }
    }
}

export default Log