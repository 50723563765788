import { Dashboard } from '@uppy/vue'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import GoldenRetriever from '@uppy/golden-retriever'

export default defineNuxtPlugin((nuxtApp) => {
    const colorMode = useColorMode()
    
    nuxtApp.vueApp.component("UppyDash", {
        props: ['uppy', 'props'],
        setup(props) {
            const theme = ref(colorMode.preference === 'dark' ? 'dark' : 'light')
            
            const updateDashboardTheme = (newTheme) => {
                if (props.uppy && props.uppy.getPlugin('Dashboard')) {
                    props.uppy.getPlugin('Dashboard').setOptions({ theme: newTheme })
                }
            }
            
            onMounted(() => {
                updateDashboardTheme(theme.value)
            })
            
            watch(() => colorMode.preference, (newPreference) => {
                theme.value = newPreference === 'dark' ? 'dark' : 'light'
                updateDashboardTheme(theme.value)
            }, { immediate: true })
            
            return () => h(Dashboard, {
                uppy: props.uppy,
                ...props.props,
                theme: theme.value
            })
        }
    })
    
    const uppy = (dashboardId: string) => {
        return new Uppy({
            id: dashboardId
        }).use(Tus, {
            endpoint: useRuntimeConfig().public.UPLOAD_SERVER,
            retryDelays: [0, 1000, 3000, 5000],
            chunkSize: 20000000,
            storeFingerprintForResuming: true,
            removeFingerprintOnSuccess: true,
        }).use(GoldenRetriever)
    }
    
    return {
        provide: {
            uppy,
        },
    };
});