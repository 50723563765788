import { merge } from "lodash";
import AssetModel from "~/packages/core/models/AssetModel";
import AuthorsCollection from "~/packages/core/models/AuthorsCollection";
import CategoriesCollection from "~/packages/core/models/CategoriesCollection";
import RelatedAssetsCollection from "~/packages/core/models/RelatedAssetsCollection";
import VendorDirectoryCollection from "~/packages/core/models/marketing/VendorDirectoryCollection";
import type {ModelOptions} from "~/packages/core/models/BaseModel";
import CampaignModel from "~/packages/core/models/clientservices/CampaignModel";

class WhitepaperModel extends AssetModel {
    static fields: string[] = ['title', 'id']
    static search_fields: string = "title,id"

    casts() {
        return merge(super.casts(), ({
            id:"number",
            gated_asset: "number",
            sponsorship_logo: "number",
            title: "string",
            author: "string",
            authors: AuthorsCollection,
            description: "string",
            teaser: "string",
            category: "string",
            dl_btn_text: "string",
            source: "string",
            pdf: "string",
            downloads: "number",
            keywords: "string",
            front_page: "number",
            wp_front_page: "number",
            on_careers_featured: "number",
            active: "string",
            logo: "string",
            image_caption: "string",
            url: "string",
            fp_view: "string",
            campaign_id: "number",
            active_campaign: "number",
            has_confirmation_page: "number",
            vendor_id: "number",
            required_level: "number",
            required_level_vp: "number",
            content_match: "string",
            p_prospect: "number",
            pprospect_url: "string",
            qone: "string",
            qone_a: "string",
            qtwo: "string",
            qtwo_a: "string",
            qthree: "string",
            qthree_a: "string",
            qfour: "string",
            qfour_a: "string",
            cbquestion: "string",
            cbanswer: "string",
            cbquestion2: "string",
            cbanswer2: "string",
            qmessage: "string",
            fplogo: "string",
            price: "string",
            vendor_teaser: "string",
            email_teaser: "string",
            email_title: "string",
            email_btn_text: "string",
            popup_details: 'object',
            canonical_url: "string",
            title_original: "string",
            cloned_hierarchy: "string",
            landing_only: "number",
            lang: "string",
            vendor_directory: VendorDirectoryCollection,
            premium: "object",
            categories: CategoriesCollection,
            related: RelatedAssetsCollection,
            campaign: CampaignModel
        }));
    }
    /**** GETTERS ****/

    /**
     * Getter example use case
     */
    // @ts-ignore
    // get exerpt(): string {
    //   if (this.description) {
    //     return this.description.substring(0, 30) + ...;
    //   }
    // }
    /**** GETTERS END ****/
    /**
     * uncomment this method in the model, if current model has different, key name, than
     * the default ('id')
     */
    getDefaultOptions(): ModelOptions {
        return {
            identifier: 'id'
        }
    }

    /**SETTERS */
    //setting vendor id on sponsor form
    set VendorID(vendorID: Number) {
        if (this.vendor_id != vendorID) this.vendor_id = vendorID
    }

    //email content
    // set InterviewEmailContent(email_content: string) {
    //     this.email_content = email_content ===  ? this.description : email_content;
    // }
}

export default WhitepaperModel;
